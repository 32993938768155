import MarkdownInput from "ra-input-markdown"
import React, { FC } from "react"
import {
  ArrayInput,
  AutocompleteInput,
  ChipField,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  List,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  SimpleFormProps,
  TextField,
  TextInput,
} from "react-admin"

import MediaWithUpload from "./components/MediaWithUpload"
import { TrackPublishingStatus, TrackType } from "./generated/graphql"
import { SearchFilter } from "./utils"

const trackStatusChoices = [
  ...Object.keys(TrackPublishingStatus).map((k) => ({
    id: k,
    name: k,
  })),
]

const trackTypeChoices = [
  ...Object.keys(TrackType).map((k) => ({
    id: k,
    name: k,
  })),
]

const CreateEditForm = (props: Omit<SimpleFormProps, "children">) => {
  const id = props["id"] as string | undefined
  return (
    <SimpleForm {...props}>
      <ReferenceInput reference="Community" source="community">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput isRequired source="title" />
      <MarkdownInput source="description" />
      <ArrayInput source="tags">
        <SimpleFormIterator>
          <TextInput source={""} />
        </SimpleFormIterator>
      </ArrayInput>
      <SelectInput choices={trackTypeChoices} source="type" />
      <SelectInput choices={trackStatusChoices} source="status" />
      <TextInput
        helperText="when adding steam link manually, add ?type=audio at the and of the link"
        source="mediaUrl"
      />
      <MediaWithUpload filename={id} path="radio/media" source="mediaUrl" />
      <DateTimeInput source="start" />
      <DateTimeInput source="end" />
      <NumberInput
        helperText="this is need to show duration before buffering. automatically handled on CA"
        label="Duration (in seconds)"
        source="duration"
      />
      <h3>Cover URL</h3>
      <MediaWithUpload filename={id} path="radio/cover" source="coverUrl" />
      <TextInput source="artist" />
      <MarkdownInput source="artistDetails" />
      <ArrayInput source="socialMediaLinks">
        <SimpleFormIterator>
          <TextInput source={""} />
        </SimpleFormIterator>
      </ArrayInput>
      {props.children}
    </SimpleForm>
  )
}

export const TrackCreate: FC<any> = (props) => (
  <Create {...props}>
    <CreateEditForm />
  </Create>
)

export const TrackEdit: FC<any> = (props) => {
  return (
    <Edit {...props}>
      <CreateEditForm>
        <DateTimeInput source="createdAt" />
      </CreateEditForm>
    </Edit>
  )
}

export const TrackList: FC<any> = (props) => (
  <List {...props} filters={SearchFilter(props)}>
    <Datagrid rowClick="edit">
      <TextField source="title" />
      <ReferenceField reference="Community" source="community">
        <ChipField source="name" />
      </ReferenceField>
      <ChipField source="type" />
      <ChipField source="status" />
      <DateField source="start" />
      <DateField source="end" />
    </Datagrid>
  </List>
)
